
// quick test

// $primary:       #506267;
// $secondary:     #7b8a8b;
// $success:       #2a76b5;
// $info:          #4892ad;
// $warning:       #dbb004;
// $danger:        #db2704;
// $light:         #ededed;
// $dark:          #031d23;
// $body-bg: #031d23;
// $body-color: #ededed;


// $primary:       #6C8e26;
// $secondary:     #485a20;
// $success:       #2a76b5;
// $info:          #4892ad;
// $warning:       #dbb004;
// $danger:        #db2704;
// $light:         #cbdc8e;
// $dark:          #0f1908;
// $body-bg: #1b2d0f;
// $body-color: #e9f2e3;

// $primary:       #103038;
// $secondary:     #174757;
// $success:       #2a76b5;
// $info:          #4892ad;
// $warning:       #dbb004;
// $danger:        #a73924;
// $light:         #F0F0F0;
// $dark:          #031D23;
// $body-color: #031D23;
// $body-bg: #031D23;


$primary:       #13444A;
$secondary:     #532605;
$success:       #1F3D77;
$info:          #4892ad;
$warning:       #dbb004;
$danger:        #a73924;
$light:         #F0F0F0;
$dark:          #05343C;
$body-color: #05343C;
$body-bg: #05343C;

// $popover-bg: #15606a !default;
// $popover-body-color: #F0F0F0 !default;
// $popover-header-bg: #15606a !default;
// $popover-header-color: #F0F0F0 !default;

@import "~bootstrap/scss/bootstrap.scss";
