.leapfrog-image{
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.596), 0 5px 15px 0 rgba(0, 0, 0, 0.635);
}
.main-title{
    text-shadow: 1px 1px 3px #1e7e6e;
}

.main-card{
    padding: 20px;
    width: 99vw;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 25px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.596), 0 5px 15px 0 rgba(0, 0, 0, 0.635);
}

.card-body{
    
    max-height:75vh;
    overflow-y:scroll;
}


/* .main-card .card-body{
    max-height: 75vh; 
    overflow-y: auto;
    overflow-x: auto;
} */

.main-card table thead tr th {
    text-align: center;
    
}
.updated_dt{
    white-space: nowrap;
}

.table-col-name{
    white-space: nowrap;
    cursor: pointer;

}


.table > thead > tr > th{
    z-index: 2;
    background: #E5E5E5;
    /* color: white; */
    position: sticky;
    top: -16px; /* Don't forget this, required for the stickiness */
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 2px;
   
}

td:nth-child(3) {
    width: 400px;
}

.val-name{
    overflow: hidden;
    max-width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
}
.val-icons{
    white-space: nowrap;
}

.validator-card{
    cursor: pointer;
    max-height: 48px;
    max-width: 52vw;
    width: 400px;
    
    overflow: hidden;
   
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 6px;
    padding-top: 3px;
    border-radius: 25px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.35), 0 2px 10px 0 rgba(0, 0, 0, 0.35);
}
/* .commission-column{
    max-width: 5vw;
} */

/* .validator-popup{
    maxwidth: 55vw;
} */
.data-card{
    /* font-weight: 400; */
    /* width: 5em;
    height: 5em; */
    text-align: center;
    /* padding: 15px; */
    border-radius: 25px;
    padding-top: 0.7em;
    white-space: nowrap;
    font-weight: 400;
    /* padding-bottom: 2em; */
    /*
    padding: 20px;
    border-radius: 25px; */
}

img{
    border-radius: 10px;
}

.progressBar{
    background-color: black;

}

.right-to-center{
   
    display: 'flex';
    align-items: 'right';
    justify-content: 'right';
  
    
}

@media screen and (max-width: 500px) {
    .main-card{
        padding: 5px;
        width: 100vw;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 5px;
        
    }
    .right-to-center{   
        display: 'flex';
        align-items: 'right';
        justify-content: 'right';             
    }

}